<template>
	<a-space direction="vertical" size="large" style="width: 100%;">
		<a-row type="flex" justify="start">
			<a-col :span="12">
				<RatingsResume />
			</a-col>
		</a-row>
		<a-row type="flex" justify="start">
			<RatingsList />
		</a-row>
	</a-space>
</template>

<script>
import { RatingsResume, RatingsList } from './components'

export default {
	name: 'RatingsPage',
	components: {
		RatingsResume,
		RatingsList,
	},
}
</script>
